// Agent.jsx
import React, { useState, useEffect } from 'react';
import './agent.css';
import agentData from '../../../../agent_list.json';

const Agent = ({ agent, setAgent }) => {
  const [agentList, setAgentList] = useState([]);

  useEffect(() => {
    // agentDataからagentListを生成
    const agents = Object.entries(agentData).map(([name, agentInfo]) => ({
      name,
      ...agentInfo
    }));
    setAgentList(agents);
  }, []);

  const handleAgentClick = (agentName) => {
    setAgent(agentName);
  };

  return (
    <div className="agent-container">
      <div className="agent-grid">
        {agentList.map((agent_i) => (
          <div
            key={agent_i.name}
            className={`agent-tile ${agent === agent_i.name ? 'selected' : ''}`}
            onClick={() => handleAgentClick(agent_i.name)}
          >
            <img src={agent_i.url} alt={agent_i.name} />
            <div className='agent-description-container'>
              <h3>{agent_i.type}</h3>
              <h3>{agent_i.name}</h3>
              <span
                className="text"
                dangerouslySetInnerHTML={{ __html: agent_i.description }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Agent;