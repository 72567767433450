import React, { useState } from 'react';
import agentData from '../../../agent_list.json';
import './AgentSelect.css';
import { db } from '../../../lib/firebase';
import { doc, updateDoc } from 'firebase/firestore';

const AgentSelect = ({ chatID, agent, setAgent }) => {
  const [hoveredAgent, setHoveredAgent] = useState(null);

  const handleAgentClick = async (selectedAgent) => {
    setAgent(selectedAgent);

    // Firebase Firestore の projects コレクションの chatID ドキュメントを更新
    const projectRef = doc(db, 'projects', chatID);
    await updateDoc(projectRef, { agent: selectedAgent });
  };

  const handleMouseEnter = (agentName) => {
    setHoveredAgent(agentName);
  };

  const handleMouseLeave = () => {
    setHoveredAgent(null);
  };

  return (
    <div className="agent-select-container">
      {Object.entries(agentData)
        .filter(([agentName]) => agentName !== 'HDY Planner')
        .map(([agentName, agentInfo]) => (
          <div
            key={agentName}
            className="agent-item"
            onMouseEnter={() => handleMouseEnter(agentName)}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={agentInfo.url_square}
              alt={agentName}
              className={`agent-image ${agent === agentName ? 'selected' : ''}`}
              onClick={() => handleAgentClick(agentName)}
            />
            {hoveredAgent === agentName && (
              <div className="agent-description">
                <span dangerouslySetInnerHTML={{ __html: agentInfo.description }} />
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default AgentSelect;