import React from 'react'
import "./createnewprojectbutton.css"

const CreateNewProjectButton = ({ setIsCreatingNewPJ }) => {

    const handleClick = () => {
        console.log("CreateNewPJボタン")
        setIsCreatingNewPJ(true);
    }
    /*
    return (
        <button className="button" onClick={handleCrlick}>
            新規プロジェクト作成
        </button>
        
    )*/
    return (
        <div className='container'>
            <button onClick={handleClick}>
                New Project
            </button>
        </div>
    )
}

export default CreateNewProjectButton