// ChatPage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../lib/firebase';
import Panel from './panel/Panel';
import Chat from './chat/Chat';
import AgentSelect from './AgentSelect/AgentSelect';
import ToggleButton from './panel/ToggleButton';
import './ChatPage.css';

const ChatPage = ({ currentUser, chatID, setWaveState }) => {
    const [isPanelOpen, setIsPanelOpen] = useState(true);
    const [settings, setSettings] = useState({})
    const [hueRotate, setHueRotate] = useState(0);

    const [agent, setAgent] = useState("")

    useEffect(() => {
        // チャットルームに入ったときにランダムな hue-rotate の角度を設定
        const randomAngle = Math.floor(Math.random() * 360);
        setHueRotate(randomAngle);
    }, [])
    //const navigate = useNavigate();

    /*
    useEffect(() => {
        const checkCsvFile = async () => {
            try {
                const csvRef = ref(storage, `csv-data/${chatID}/過去出稿.csv`);
                await getDownloadURL(csvRef);
            } catch (error) {
                console.error('CSV file does not exist:', error);
                navigate('/upload');
            }
        };

        if (chatID) {
            checkCsvFile();
        }
    }, [chatID, navigate]);
    */

    const togglePanel = () => {
        setIsPanelOpen(!isPanelOpen);
    }

    return (
        <div className="chat-page">
            <div className={`panel-container ${isPanelOpen ? 'open' : 'closed'}`}>
                <Panel settings={settings} setSettings={setSettings} chatID={chatID} hueRotate={hueRotate}/>
                <ToggleButton isPanelOpen={isPanelOpen} togglePanel={togglePanel} />
            </div>
            <div className={`chat-container ${isPanelOpen ? 'open' : 'closed'}`}>
                <AgentSelect chatID={chatID} agent={agent} setAgent={setAgent}/>
                <Chat currentUser={currentUser} chatID={chatID} agent={agent} setAgent={setAgent} settings={settings} setSettings={setSettings} hueRotate={hueRotate} setWaveState={setWaveState}/>
            </div>
        </div>
    );
}

export default ChatPage;
