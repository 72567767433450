import React from "react";
import "./hambergerbutton.css";


export const HambergerButton = ({ open, setOpen }) => {

  return (
    <div className={`openbtn1 ${open ? 'active' : ''}`} onClick={setOpen}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};
