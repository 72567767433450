// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { doc, setDoc, getDocs, collection } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyBhuh8ruaOYX38R2QRs_mJOkktL5wonkq8",
    authDomain: "cvsim-webui.firebaseapp.com",
    projectId: "cvsim-webui",
    storageBucket: "cvsim-webui.appspot.com",
    messagingSenderId: "1022743719801",
    appId: "1:1022743719801:web:6932b78d567e99350bc9a8"
  };

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);


export const getUsers = async () => {
    const usersSnapshot = await getDocs(collection(db, "users"));
    const usersList = usersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log(usersList)
    return usersList;
  };

  // Firestoreにユーザー情報を追加する関数
export const addUserToFirestore = async (user) => {
    try {
      await setDoc(doc(db, "users", user.uid), {
        displayName: user.displayName,
        email: user.email,
        // 他のユーザー情報を追加する
      });
      console.log("User added to Firestore");
    } catch (error) {
      console.error("Error adding user to Firestore:", error);
    }
  };