import React from 'react';
import "./edamenu.css";

const EDAMenu = ({ menuName, menuData }) => {
    return (
        <div className={`per-mt-container`}>
            <div className="mt-eda-content">
                {Object.entries(menuData).map(([key, value]) => (
                    <div key={key} className='mt-eda-item'>
                        <div className='mt-eda-key'>{key}</div>
                        <div className='mt-eda-value'>{value}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EDAMenu;