// ToggleButton.jsx
import React from 'react';
import './ToggleButton.css';
import openIcon from "./open.png"
import closedIcon from "./closed.png"

const ToggleButton = ({ isPanelOpen, togglePanel }) => {
    return (
        <div className='toggle-button-container'> 
            <div className="toggle-button"  onClick={togglePanel}>
                <img src={isPanelOpen ? openIcon : closedIcon} />
            </div>
        </div>
    );
}

export default ToggleButton;
