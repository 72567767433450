// src/components/chatpage/panel/Logout.jsx
import React from "react";
import { auth } from "../../../lib/firebase";
import { signOut } from "firebase/auth";
import "./logout.css"

const Logout = () => {
  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <button className="logout-button" onClick={handleLogout}>
      Logout
    </button>
  );
};

export default Logout;
