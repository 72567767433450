// ProjectGallery.jsx
import React, { useState, useEffect } from "react";
import { db } from "../../../../lib/firebase";
import { doc, getDoc } from "firebase/firestore";
import "./projectgallery.css";
import { useNavigate } from "react-router-dom";
import agentData from "../../../../agent_list.json";

const ProjectGallery = ({ currentUser, setChatID, chatID }) => {
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                // ユーザーのプロジェクトIDのリストを取得
                const userPJsRef = doc(db, "userPJs", currentUser.uid);
                const userPJsDoc = await getDoc(userPJsRef);

                if (userPJsDoc.exists()) {
                    const { projectIds } = userPJsDoc.data();

                    // 各プロジェクトの情報を取得
                    const projectsData = await Promise.all(
                        projectIds.map(async (projectId) => {
                            const projectRef = doc(db, "projects", projectId);
                            const projectDoc = await getDoc(projectRef);

                            if (projectDoc.exists()) {
                                const { projectTitle, modifiedAt, agent, cpa_array } = projectDoc.data();
                                return { id: projectDoc.id, projectTitle, modifiedAt, agent, cpa_array };
                            }
                        })
                    );

                    // modifiedAtの日時が新しい順に並び替え
                    const sortedProjects = projectsData.filter(Boolean).sort((a, b) => b.modifiedAt.toDate() - a.modifiedAt.toDate());
                    setProjects(sortedProjects);

                    // chatIDがnullのときには日時が最新のプロジェクトのchatIDをセット
                    if (!chatID && sortedProjects.length > 0) {
                        setChatID(sortedProjects[0].id);
                    }
                }
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        fetchProjects();
    }, [currentUser.uid, chatID, setChatID]);

    const handleProjectClick = (projectId) => {
        setChatID(projectId);
        navigate("/plan")
    };

    return (
        <div className="project-gallery-container">
            <div className="fukidashi-container">
                <div className='fukidashi'>
                    編集したいプロジェクトを選択してください
                </div>
            </div>
            <div className="spacer"></div>
            {projects.length > 0 ? (
                <div className="project-grid">
                    {projects.map((project) => (
                        <div
                            key={project.id}
                            className="project-tile"
                            onClick={() => handleProjectClick(project.id)}
                        >
                            <div className="project-image-container">
                                <img src={agentData[project.agent].url} alt={project.projectTitle} />
                                <div className="project-info">
                                    <h3>{project.projectTitle}</h3>
                                    <p>{project.modifiedAt.toDate().toLocaleString()}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>プロジェクトがありません。</p>
            )}
        </div>
    );
};

export default ProjectGallery;