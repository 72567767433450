import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { doc, onSnapshot, updateDoc, arrayUnion, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../lib/firebase';
import './chat.css';
import agentData from '../../../agent_list.json';

const Chat = ({ currentUser, chatID, agent, setAgent, settings, setSettings, hueRotate, setWaveState }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const scrollBottomRef = useRef(null);

    //const [settings, setSettings] = useState({"test": -1});

    useEffect(() => {
        scrollToBottom()
    }, [messages])

    useEffect(() => {
        const chatRef = doc(db, 'projects', chatID);
        const unsubscribe = onSnapshot(chatRef, (snapshot) => {
            if (snapshot.exists()) {
                const messagesData = snapshot.data().messages || [];
                setMessages(messagesData);

                const agentData = snapshot.data().agent || "tomoko"
                setAgent(agentData)

                const jsonStr = snapshot.data().settings || "{}"
                const jasonObj = JSON.parse(jsonStr)
                setSettings(jasonObj)
            }
        });
        console.log(chatID)

        return () => unsubscribe();
    }, [chatID]);

    // リストを一番下までスクロールする関数
    const scrollToBottom = () => {
        if (scrollBottomRef.current) {
            scrollBottomRef.current.scrollTop = scrollBottomRef.current.scrollHeight
        }
    };

    const start_run_cv_simulator = () => {
        setWaveState("strong")
    };

    const end_run_cv_simulator = () => {
        setWaveState("normal")
    };



    const handleSendMessage = async () => {
        if (newMessage.trim() !== '') {
            const chatRef = doc(db, 'projects', chatID);
            const timestamp = new Date().getTime();

            // ユーザーのメッセージをmessagesに追加
            const updatedMessages = [
                ...messages,
                {
                    senderId: currentUser.uid,
                    systemMessage: "",
                    text: newMessage,
                    timestamp: timestamp,
                },
            ];
            setMessages(updatedMessages);
            setNewMessage('');

            try {
                // FastAPIにAPIコールしてボットの返信を取得
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chat`, { //'/api/chat', {   
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        message: newMessage,
                        settings: settings,
                        agent: agent,
                        chatID: chatID
                    }),
                });

                const data = await response.json();
                const { systemMessage, message: botReply, settings: newSettings, flag } = data;

                const finalMessages = [
                    ...updatedMessages,
                    {
                        senderId: agent,
                        systemMessage,
                        text: botReply,
                        timestamp: timestamp + 1,
                    }
                ];

                // ボットが返信してきたsettingsに更新
                setSettings(newSettings);
                setMessages(finalMessages);

                if (flag === 1) {
                    /* flag==1のときcv_simulator実行開始される */
                    start_run_cv_simulator();
                    const runResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/run`, { //"http://localhost:8000/run", {//'/api/run', {   
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            settings: newSettings,
                            agent: agent,
                            chatID: chatID
                        }),
                    });
                    end_run_cv_simulator();
                    const runData = await runResponse.json();
                    const { systemMessage: systemMessage2, message: botReply2, settings: newSettingsRun} = runData;
                    const finalMessages2 = [
                        ...finalMessages,
                        {
                            senderId: agent,
                            systemMessage: systemMessage2,
                            text: botReply2,
                            timestamp: timestamp + 2,
                        }
                    ];
                    setMessages(finalMessages2);
                    setSettings(newSettingsRun);
                    console.log("new_settings", newSettingsRun)
                    // データベースを更新
                    await updateDoc(chatRef, {
                        messages: arrayUnion(...finalMessages2.slice(-3)),
                        settings: JSON.stringify(newSettings),
                        modifiedAt: serverTimestamp(),
                    });
                } else {
                    // データベースを更新
                    await updateDoc(chatRef, {
                        messages: arrayUnion(...finalMessages.slice(-2)),
                        settings: JSON.stringify(newSettings),
                        modifiedAt: serverTimestamp(),
                    });
                }
            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSendMessage();
        }
    };

    return (
        <div className="chat">
            <div className="chat-messages" ref={scrollBottomRef}>
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`message-container ${message.senderId === currentUser.uid ? 'sent' : 'received'}`}
                    >
                        {message.senderId !== currentUser.uid && (
                            <img
                                src={agentData[message.senderId].url_square}
                                alt={message.senderId}
                                className="profile-image"
                            />
                        )}
                        <div className="message">
                            {message.systemMessage && (
                                <div className="system-message">
                                    <span>{message.systemMessage}</span>
                                </div>
                            )}
                            <span
                                className="text"
                                dangerouslySetInnerHTML={{ __html: message.text }}
                            />
                        </div>
                    </div>
                ))}
            </div>


            <div class="messageBox">
                <input
                    required=""
                    placeholder="Write here..."
                    type="text"
                    id="messageInput"
                    value={newMessage}
                    autocomplete="off"
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <button id="sendButton" onClick={handleSendMessage}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 664 663">
                        <path
                            fill="none"
                            d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                        /*style={{
                            filter: `hue-rotate(${hueRotate}deg)`,
                        }}*/
                        ></path>
                        <path
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            stroke-width="33.67"
                            stroke="#6c6c6c"
                            d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                        /*style={{
                            filter: `hue-rotate(${hueRotate}deg)`,
                        }}*/
                        ></path>
                    </svg>
                </button>
            </div>

        </div>
    );
};

export default Chat;