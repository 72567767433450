// components/Login.jsx
import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, addUserToFirestore, db } from "../../lib/firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "./login.css";
import logo from "./cvsim_logo.png"


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // useNavigateフックを使用

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Firestoreにユーザー情報が保存されているかチェックする
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (!userDoc.exists()) {
        await addUserToFirestore(user);
      } else {
        const userData = userDoc.data();
        if (!userData.profileImage) {
          const defaultProfileImageURL = "https://via.placeholder.com/50";
          await updateDoc(doc(db, "users", user.uid), {
            profileImage: defaultProfileImageURL,
          });
        }
      }

      // userChatRoomsコレクションを作成または更新する
      const userChatRoomRef = doc(db, "userPJs", user.uid);
      const userChatRoomSnapshot = await getDoc(userChatRoomRef);
      if (!userChatRoomSnapshot.exists()) {
        await setDoc(userChatRoomRef, {
          pj_IDs: [],
        });
      }

      navigate("/home"); // ログイン成功後にChatPageに遷移

    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login">
      <div className="item">
        <img src={logo}></img>
        {error && <p>{error}</p>}
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">ログイン</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
