import React from "react";
import CreateNewProjectButton from "./CreateNewProjectButton/CreateNewProjectButton";
import ProjectGallery from "./ProjectGallery/ProjectGallery";
import "./defaulthome.css"

export const DefaultHome = ({ currentUser, setIsCreatingNewPJ, setChatID, chatID }) => {

    return (
        <div className="default-home-container">
            <CreateNewProjectButton setIsCreatingNewPJ={setIsCreatingNewPJ}/>
            <ProjectGallery currentUser={currentUser} setChatID={setChatID} chatID={chatID}/>
        </div>
    )
};

export default DefaultHome;
