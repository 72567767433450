// src/components/UserProfile.jsx
import React, { useState, useEffect } from "react";
import { storage, db, auth } from "../../../lib/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { updateProfile } from "firebase/auth";
import "./userprofile.css";

const UserProfile = ({ currentUser }) => {
  const [profileImage, setProfileImage] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [editingDisplayName, setEditingDisplayName] = useState(false);

  useEffect(() => {
    const fetchProfileData = async () => {
      const userDoc = await getDoc(doc(db, "users", currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.profileImage) {
          setProfileImage(userData.profileImage);
        }
        if (userData.displayName) {
          setDisplayName(userData.displayName);
        } else {
          setDisplayName("No Name")
        }

      }
    };

    fetchProfileData();
  }, [currentUser.uid]);

  const handleProfileImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const storageRef = ref(storage, `profile-images/${currentUser.uid}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      setProfileImage(downloadURL);

      // Firestoreのユーザー情報を更新する
      await updateDoc(doc(db, "users", currentUser.uid), {
        profileImage: downloadURL,
      });
    }
  };

  const handleDisplayNameChange = async (newDisplayName) => {
    try {
      await updateProfile(auth.currentUser, { displayName: newDisplayName });
      await updateDoc(doc(db, "users", currentUser.uid), {
        displayName: newDisplayName,
      });
      setDisplayName(newDisplayName);
      setEditingDisplayName(false);
    } catch (error) {
      console.error("Error updating display name:", error);
    }
  };

  return (
    <div className="user-profile">
      <img
        src={profileImage || "https://via.placeholder.com/50"}
        alt="Profile"
        className="profile-image"
        onClick={() => document.getElementById("profile-image-input").click()}
      />
      <div className="display-name">
        
        {/*editingDisplayName ? (
          <input
            type="text"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            onBlur={() => handleDisplayNameChange(displayName)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleDisplayNameChange(displayName);
              }
            }}
            autoFocus
          />
        ) : (
          <span onClick={() => setEditingDisplayName(true)}>{displayName}</span>
        )*/}
        <input
          className="display-name-input"
          type = "text"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleDisplayNameChange(displayName);
            }
          }}
        />
      </div>
      <input
        type="file"
        id="profile-image-input"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleProfileImageUpload}
      />
    </div>
  );
};

export default UserProfile;
