import React, { useState } from 'react';
import { ref, getStorage, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../lib/firebase';
import { db } from "../../lib/firebase";
import { doc, updateDoc } from "firebase/firestore";

import csvIcon from './csvIcon.png';
import "./upload.css"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Upload = ({ currentUser, chatID, setCpa, setWaveState }) => {
  const [csvFile, setCsvFile] = useState(null);
  const [filePathDisplay, setFilePathDisplay] = useState('');
  const navigate = useNavigate()

  const handleFileChange = (event) => {
    setCsvFile(event.target.files[0]);
    setFilePathDisplay(event.target.value);
  };

  const handleUpload = async () => {
    if (csvFile) {
      try {
        const csvRef = ref(storage, `csv-data/${chatID}/過去出稿.csv`);
        await uploadBytes(csvRef, csvFile);
        console.log('CSV file uploaded successfully');

        // FastAPIにAPIコールしてボットの返信を取得
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/EDA`, {     //'/api/EDA', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            //messages: updatedMessages,
            chatID: chatID
          }),
        })
        const data = await response.json();
        const cpa = data.cpa;
        setCpa(cpa)
        setWaveState("normal")

        /* firestoreにアップロード */
        const projectRef = doc(db, "projects", chatID);
        await updateDoc(projectRef, {
          cpa_array: cpa
        });

        navigate("/plan")
      } catch (error) {
        console.error('Error uploading CSV file or calling EDA endpoint:', error);
      }
    }
  };
  const SampleCSVIcon = () => (
    <svg className='sample-csv' fill="#FFF" height="30px" width="30px" version="1.1" id="Capa_1" viewBox="0 0 29.978 29.978">
      <path d="M25.462,19.105v6.848H4.515v-6.848H0.489v8.861c0,1.111,0.9,2.012,2.016,2.012h24.967c1.115,0,2.016-0.9,2.016-2.012v-8.861H25.462z"/>
      <path d="M14.62,18.426l-5.764-6.965c0,0-0.877-0.828,0.074-0.828s3.248,0,3.248,0s0-0.557,0-1.416c0-2.449,0-6.906,0-8.723c0,0-0.129-0.494,0.615-0.494c0.75,0,4.035,0,4.572,0c0.536,0,0.524,0.416,0.524,0.416c0,1.762,0,6.373,0,8.742c0,0.768,0,1.266,0,1.266s1.842,0,2.998,0c1.154,0,0.285,0.867,0.285,0.867s-4.904,6.51-5.588,7.193C15.092,18.979,14.62,18.426,14.62,18.426z"/>
    </svg>
  );

  const handleClickSample = async () => {
    try {
      // Firebase Storage のインスタンスを取得
      const storage = getStorage();

      // ファイルへの参照を作成
      const fileRef = ref(storage, 'sample/sample-input.csv');

      // ダウンロード URL を取得
      const downloadURL = await getDownloadURL(fileRef);

      // 一時的な <a> 要素を作成してダウンロードを実行
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = 'input-sample.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
    } catch (error) {
      console.error("ファイルのダウンロード中にエラーが発生しました:", error);
    }
  };

  return (
    <div className='upload-container-whole'>
      <div className='fukidashi'>
        今回モデル作成に用いるデータをインポートしてください
      </div>
      <div className='fukidashi'>
        pptx, docx, xlsx, csvを扱うことができます
      </div>
      <div className='sample' onClick={handleClickSample}>
        <SampleCSVIcon />
        <div>インプットサンプル.csv</div>
      </div>
      <div className='upload-container'>

        <label htmlFor="csvFileInput" className="csv-input-label">
          <svg className='csv-icon-container' xmlns="http://www.w3.org/2000/svg">
            <path
              className="csv-icon-theme"
              d="M5.106 0c-2.802 0-5.073 2.272-5.073 5.074v53.841c0 2.803 2.271 5.074 5.073 5.074h45.774c2.801 0 5.074-2.271 5.074-5.074v-38.605l-18.903-20.31h-31.945z"
              fillRule="evenodd"
              clipRule="evenodd"
            />
            <path
              d="M20.306 43.197c.126.144.198.324.198.522 0 .378-.306.72-.703.72-.18 0-.378-.072-.504-.234-.702-.846-1.891-1.387-3.007-1.387-2.629 0-4.627 2.017-4.627 4.88 0 2.845 1.999 4.879 4.627 4.879 1.134 0 2.25-.486 3.007-1.369.125-.144.324-.233.504-.233.415 0 .703.359.703.738 0 .18-.072.36-.198.504-.937.972-2.215 1.693-4.015 1.693-3.457 0-6.176-2.521-6.176-6.212s2.719-6.212 6.176-6.212c1.8.001 3.096.721 4.015 1.711zm6.802 10.714c-1.782 0-3.187-.594-4.213-1.495-.162-.144-.234-.342-.234-.54 0-.361.27-.757.702-.757.144 0 .306.036.432.144.828.739 1.98 1.314 3.367 1.314 2.143 0 2.827-1.152 2.827-2.071 0-3.097-7.112-1.386-7.112-5.672 0-1.98 1.764-3.331 4.123-3.331 1.548 0 2.881.467 3.853 1.278.162.144.252.342.252.54 0 .36-.306.72-.703.72-.144 0-.306-.054-.432-.162-.882-.72-1.98-1.044-3.079-1.044-1.44 0-2.467.774-2.467 1.909 0 2.701 7.112 1.152 7.112 5.636.001 1.748-1.187 3.531-4.428 3.531zm16.994-11.254l-4.159 10.335c-.198.486-.685.81-1.188.81h-.036c-.522 0-1.008-.324-1.207-.81l-4.142-10.335c-.036-.09-.054-.18-.054-.288 0-.36.323-.793.81-.793.306 0 .594.18.72.486l3.889 9.992 3.889-9.992c.108-.288.396-.486.72-.486.468 0 .81.378.81.793.001.09-.017.198-.052.288z"
              fill="rgba(0,0,0,0.5)"
            />
            <g fillRule="evenodd" clipRule="evenodd">
              <path d="M56.001 20.357v1h-12.8s-6.312-1.26-6.128-6.707c0 0 .208 5.707 6.003 5.707h12.925z" fill="#349C42" />
              <path d="M37.098.006v14.561c0 1.656 1.104 5.791 6.104 5.791h12.8l-18.904-20.352z" opacity=".5" fill="#ffffff" />
            </g>
          </svg>
        </label>
        <input
          id="csvFileInput"
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          className="csv-input"
        />
        {csvFile && (
          <div className="file-info">
            <p>{csvFile.name}</p>
            <button onClick={handleUpload}>Upload</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Upload;
