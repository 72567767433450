import React, { useRef, useMemo, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';

const waveWidth = 300;
const waveHeight = 200;
const waveFrequency = 0.04;
const waveStrength = 20;
const waveAmplitude = 10;



function ParticleSystem({ props }) {
    const cpa = props.cpa;
    const waveSpeed = props.waveSpeed

    const particlesRef = useRef();
    const clock = useMemo(() => new THREE.Clock(), []);

    const particlePositions = useMemo(() => {
        const positions = [];
        function catmullRomInterpolate(p0, p1, p2, p3, t) {
            const v0 = (p2 - p0) * 0.5;
            const v1 = (p3 - p1) * 0.5;
            const t2 = t * t;
            const t3 = t * t * t;
            return (2 * p1 - 2 * p2 + v0 + v1) * t3 + (-3 * p1 + 3 * p2 - 2 * v0 - v1) * t2 + v0 * t + p1;
        }

        function interpolateHeight(x) {
            const t = x / waveWidth;
            const segment = t * (cpa.length - 1);
            const i = Math.floor(segment);
            const localT = segment - i;

            const p0 = cpa[Math.max(i - 1, 0)];
            const p1 = cpa[i];
            const p2 = cpa[Math.min(i + 1, cpa.length - 1)];
            const p3 = cpa[Math.min(i + 2, cpa.length - 1)];

            return catmullRomInterpolate(p0, p1, p2, p3, localT);
        }

        for (let i = 0; i < waveWidth; i++) {
            for (let j = 0; j < waveHeight; j++) {
                const y = interpolateHeight(i, j);
                positions.push(i - waveWidth / 2, y, j - waveHeight / 2);
            }
        }
        //console.log('Particle positions initialized:', positions);
        return positions;
    }, []);

    useFrame(() => {
        const time = clock.getElapsedTime() * waveSpeed;
        const positions = particlesRef.current.geometry.attributes.position.array;

        for (let i = 0; i < waveWidth; i++) {
            for (let j = 0; j < waveHeight; j++) {
                const index = 3 * (i * waveHeight + j);
                const baseHeight = particlePositions[index + 1];
                positions[index + 1] = baseHeight + waveAmplitude * Math.sin(i * waveFrequency + time) * Math.sin(j * waveFrequency + time);
            }
        }
        particlesRef.current.geometry.attributes.position.needsUpdate = true;
        //console.log('Particle positions updated');
    });

    const particleGeometry = new THREE.BufferGeometry();
    particleGeometry.setAttribute('position', new THREE.Float32BufferAttribute(particlePositions, 3));
    particleGeometry.setAttribute('initposition', new THREE.Float32BufferAttribute(particlePositions, 3));

    const particleMaterial = new THREE.PointsMaterial({
        size: 0.1,
        color: new THREE.Color(0.4, 0.957, 1.0),
        transparent: true,
        opacity: 0.8,
    });

    return <points ref={particlesRef} geometry={particleGeometry} material={particleMaterial} />;
}

function Box() {
    const mesh = useRef();


    useFrame(() => {
        mesh.current.rotation.x += 0.01;
        mesh.current.rotation.y += 0.01;
    });

    return (
        <mesh ref={mesh} position={[0, 0, 0]}>
            <boxGeometry args={[1, 1, 1]} />
            <meshStandardMaterial color={'orange'} />
        </mesh>
    );
}



function CpaWave({ props }) {
    useEffect(() => {
        console.log('App component mounted');
    }, []);


    return (
        <>
            <div id="info" style={{ width: '100vw', height: '100vh' }}>
                <Canvas camera={{ position: [10.11380, 20.211853, 97.13781], rotation: [500, 0, 100] }}>
                    <ambientLight />
                    <OrbitControls enableRotate />
                    <pointLight position={[10, 10, 10]} />
                    <ParticleSystem props={props} />
                </Canvas>
            </div>
        </>
    );
}

export default CpaWave;