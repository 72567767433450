// components/home/home.jsx
import React, { useState, useEffect } from "react";
import DefaultHome from "./DefaultHome/DefaultHome";
import CreateProject from "./CreateProject/CreateProject";
import "./Home.css";

const Home = ({ currentUser, setChatID, chatID }) => {
  const [isCreatingNewPJ, setIsCreatingNewPJ] = useState(false)

  return (
    <div className="home">
      <div className="spacer"></div>
      <div className={`default-home ${isCreatingNewPJ ? "slide-out" : ""}`}>
        <DefaultHome currentUser={currentUser} setIsCreatingNewPJ={setIsCreatingNewPJ} setChatID={setChatID} chatID={chatID}/>
      </div>
      <div className={`create-project ${isCreatingNewPJ ? "slide-in" : ""}`}>
        <CreateProject currentUser={currentUser} setIsCreatingNewPJ={setIsCreatingNewPJ} setChatID={setChatID}/>
      </div>
    </div>
  );
};

export default Home
