// components/menu/Menu.jsx
import React from "react";
import { NavLink } from "react-router-dom";
import UserProfile from "./userprofile/UserProfile";
import "./menu.css";
import HomeIcon from "./house-chimney.png"
import ChatIcon from "./messages.png"
import Logout from "../chatpage/panel/Logout";

const Menu = ({currentUser}) => {
  return (
    <div className="menu-container">
        <div className="menu">
            <UserProfile currentUser={currentUser} className="user-profile"/>
            <div className="menu-list">
                <NavLink to="/home" activeClassName="active">
                    Home
                </NavLink>
                <NavLink to="/upload" activeClassName="active">
                    Upload
                </NavLink>
                <NavLink to="/plan" activeClassName="active">
                    Plan
                </NavLink>
                <NavLink to="/result" activeClassName="active">
                    Result
                </NavLink>
                {/* 必要に応じて他のリンクも追加 */}

            </div>
        </div>
        <Logout className="logout"/>
    </div>
  );
};

export default Menu
