// src/components/home/CreateProject/CreateProject.jsx
import React, { useState } from 'react';
import { db } from "../../../lib/firebase";
import { collection, addDoc, serverTimestamp, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import "./createproject.css"
import Agent from './Agent/Agent';

const CreateProject = ({ currentUser, setIsCreatingNewPJ, setChatID }) => {
    const [projectTitle, setProjectTitle] = useState('');
    const [message, setMessage] = useState('');
    const [agent, setAgent] = useState('Tomoko')
    const navigate = useNavigate();

    const handleClick = () => {
        setIsCreatingNewPJ(false);
    };

    const handleProjectTitleChange = (e) => {
        setProjectTitle(e.target.value);
    };

    const createProject = async () => {
        const defaultSettings = {
            // プロジェクトの初期設定を定義
            // 例: theme: "default", language: "en", etc.
        };

        try {
            const createdAt = serverTimestamp();
            const modifiedAt = serverTimestamp();

            // 入力誘導のメッセージを追加
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/first-message`, { //'/api/chat', {   
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    agent: agent,
                }),
            });
            const data = await response.json();

            const firstMessage = {
                senderId: agent,
                systemMessage: data.systemMessage,
                text: data.message,
                timestamp: createdAt + 1,
            }
                
            console.log("firstMessage", firstMessage);


            const projectRef = await addDoc(collection(db, "projects"), {
                userId: currentUser.uid,
                projectTitle: newMessage,
                createdAt: createdAt,
                modifiedAt: modifiedAt,
                agent: agent,
                messages: [firstMessage],
                settings: JSON.stringify(defaultSettings),
            });

            console.log("Project created with ID:", projectRef.id);

            // 現在のユーザーのuserPJsコレクションを更新
            const userPJsRef = doc(db, "userPJs", currentUser.uid);
            await updateDoc(userPJsRef, {
                projectIds: arrayUnion(projectRef.id),
            });

            // プロジェクト作成後、入力フィールドをクリアし、新規プロジェクト作成画面を閉じる
            setProjectTitle('');
            setIsCreatingNewPJ(false);

            // chatIDを更新し、upload画面に遷移
            setChatID(projectRef.id)
            navigate("/upload")

        } catch (error) {
            console.error("Error creating project:", error);
        }
    };

    // メッセージ入力関連
    const [newMessage, setNewMessage] = useState('');
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSendMessage();
        }
    };
    const handleSendMessage = async () => {
        setProjectTitle(newMessage);
        setTimeout(createProject, 2000);
    };

    return (
        <div className='create-project-container'>
            <button onClick={handleClick}>
                Cancel
            </button>
            <div className='fukidashi received'>
                今回モデルを作成するプランナーを選択してください
            </div>
            <Agent agent={agent} setAgent={setAgent} />
            {/*
            <input
                type="text"
                value={projectTitle}
                onChange={handleProjectTitleChange}
                placeholder="Project Title"
            />
            <button className="create-project-button" onClick={createProject}>Create Project</button>
            <button className="cancel-button" onClick={handleClick}>Cancel</button>
    */}
            <div className='fukidashi received'>
                プロジェクトタイトル（クライアント・キャンペーン名など）を教えてください
            </div>
            {
                projectTitle != "" ? (
                    <>
                        <div className='fukidashi sent'>
                            {projectTitle}
                        </div>
                        <div className='fukidashi received'>
                            プロジェクト{projectTitle}を作成します。
                        </div>
                    </>
                ) : (
                    null
                )
            }


            <div class="messageBox">
                <input
                    required=""
                    placeholder="Write here..."
                    type="text"
                    id="messageInput"
                    value={newMessage}
                    autocomplete="off"
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <button id="sendButton" onClick={handleSendMessage}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 664 663">
                        <path
                            fill="none"
                            d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                        ></path>
                        <path
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            stroke-width="33.67"
                            stroke="#6c6c6c"
                            d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"

                        ></path>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default CreateProject;
