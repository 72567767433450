// Panel.jsx
import React, { useState, useEffect } from 'react';
import { storage } from '../../../lib/firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import './Panel.css';
import axios from 'axios';
import EDAMenu from './EDA-menu/EDAMenu';
import { HambergerButton } from './HambergerMenu/HambergerButton';
import { ResponsiveContainer, LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


const Panel = ({ settings, setSettings, chatID, hueRotate }) => {

    const [menuData, setMenuData] = useState(null);
    const [selectedColumn, setSelectedColumn] = useState('cost');
    const [menuChartData, setMenuChartData] = useState([]);
    const [edaChartData, setEdaChartData] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState(null);


    useEffect(() => {
        const fetchFiles = async () => {
            try {
                // 全体EDA.jsonをダウンロード
                const edaRef = ref(storage, `EDA-results/${chatID}/全体EDA.json`);
                const edaUrl = await getDownloadURL(edaRef);
                const edaResponse = await axios.get(edaUrl);
                const edaData = edaResponse.data.year_month.map((month, index) => ({
                    year_month: month,
                    cost: edaResponse.data.cost[index],
                    cv: edaResponse.data.cv[index],
                    cpa: edaResponse.data.cpa[index],
                }));
                setEdaChartData(edaData);

                // メニューごとEDA.jsonをダウンロード
                const menuRef = ref(storage, `EDA-results/${chatID}/メニューごとEDA.json`);
                const menuUrl = await getDownloadURL(menuRef);
                const menuResponse = await axios.get(menuUrl);
                setMenuData(menuResponse.data);
                setMenuChartData(Object.entries(menuResponse.data).map(([menu, data]) => ({ menu, ...data })));
                const menuKeys = Object.keys(menuResponse.data);
                if (menuKeys.length > 0) {
                    setSelectedMenu(menuKeys[0]);
                }

            } catch (error) {
                console.error('Error downloading files:', error);
            }
        };

        fetchFiles();

    }, [chatID]);

    // カードの表示・非表示
    const [showSummaryDetails, setShowSummaryDetails] = useState(true);
    const [showMenuDetails, setShowMenuDetails] = useState(true);
    const [showSettingDetails, setShowSettingDetails] = useState(true);

    const handleClickSummary = () => {
        setShowSummaryDetails(!showSummaryDetails);
    }
    const handleClickMenu = () => {
        setShowMenuDetails(!showMenuDetails);
        console.log("showMenuDetails", showMenuDetails)
    }
    const handleClickSetting = () => {
        setShowSettingDetails(!showSettingDetails);
    }
    const renderSettingValue = (value) => {
        if (Array.isArray(value)) {
            return value.map((item, index) => (
                <div key={index} className='setting-array-item'>
                    {item}
                </div>
            ));
        } else if (typeof value === 'object' && value !== null) {
            return Object.entries(value).map(([subKey, subValue]) => (
                <div key={subKey} className='setting-sub-item'>
                    <div className='setting-key'>{subKey}</div>
                    <div className='setting-value'>{subValue[0]} ~ {subValue[1]}</div>
                </div>
            ));
        } else {
            return <div>{value}</div>;
        }
    };

    return (
        <div className='panel'>
            <div className='card' /*style={{ filter: `hue-rotate(${hueRotate}deg)` }} */ onClick={handleClickSetting}>
                <div className={`card-content-container ${showSettingDetails ? 'show-details' : ''}`}>
                    <div className={`card-title ${showSettingDetails ? 'show-details' : ''}`}>

                        <HambergerButton
                            open={showSettingDetails}
                            setOpen={handleClickSetting}
                        />
                        <div>キャンペーン説明</div>

                    </div>
                    <div className={`card-sub-content ${showSettingDetails ? 'show-details' : ''}`}>
                        {/* 設定の表示 */}
                        {settings && Object.entries(settings).map(([key, value]) => (
                            <div key={key} className='setting-item'>
                                <div className='setting-key'>{key}</div>
                                <div className='setting-value'>{renderSettingValue(value)}</div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
            <svg width="100%" height="1">
                <line x1="3%" y1="0" x2="97%" y2="0" stroke="#dcdcdc" strokeWidth="1" />
            </svg>
            <div className='card' /*style={{ filter: `hue-rotate(${hueRotate}deg)` }} */>
                <div className={`card-content-container ${showSummaryDetails ? 'show-details' : ''}`}>
                    
                    <div className={`card-title ${showSummaryDetails ? 'show-details' : ''}`}>

                        <HambergerButton
                            open={showSummaryDetails}
                            setOpen={handleClickSummary}
                        />
                        <div>出稿サマリー</div>

                    </div>
                    <div className={`card-sub-content ${showSummaryDetails ? 'show-details' : ''}`}>
                        <div style={{ height: "10px" }}></div>
                        <table className="summary-table">
                            <thead>
                                <tr>
                                    <th
                                        className={selectedColumn === 'cost' ? 'selected' : ''}
                                        onClick={() => setSelectedColumn('cost')}
                                    >
                                        COST
                                    </th>
                                    <th
                                        className={selectedColumn === 'cv' ? 'selected' : ''}
                                        onClick={() => setSelectedColumn('cv')}
                                    >
                                        CV
                                    </th>
                                    <th
                                        className={selectedColumn === 'cpa' ? 'selected' : ''}
                                        onClick={() => setSelectedColumn('cpa')}
                                    >
                                        CPA
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <div className="chart-container">
                            <div style={{ height: 20 }}></div>
                            <div className='fig-title'>
                                推移
                            </div>
                            <ResponsiveContainer className="chart" width="90%" height={300}>
                                <LineChart data={edaChartData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="year_month" angle={-90} textAnchor="end" height={80} interval={0} tick={{ fill: 'white', fontSize: 12 }} />
                                    <YAxis tick={{ fill: 'white', fontSize: 12 }} />
                                    <Tooltip />
                                    {/*<Legend />*/}
                                    <Line type="monotone" dataKey={selectedColumn} stroke="#5f9ea0" activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                            <div className='fig-title'>
                                最新月詳細
                            </div>
                            <ResponsiveContainer className="chart" width="90%" height={300}>
                                <BarChart data={menuChartData}>
                                    {/*<CartesianGrid strokeDasharray="3 3" />*/}
                                    <XAxis dataKey="menu" angle={-90} textAnchor="end" height={150} interval={0} tick={{ fill: 'white', fontSize: 12 }} />
                                    <YAxis tick={{ fill: 'white', fontSize: 12 }} />
                                    <Tooltip />
                                    {/*<Legend />*/}
                                    <Bar dataKey={selectedColumn} fill="#5f9ea0" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            </div>
            <svg width="100%" height="1">
                <line x1="3%" y1="0" x2="97%" y2="0" stroke="#dcdcdc" strokeWidth="1" />
            </svg>
            <div className='card' /*style={{ filter: `hue-rotate(${hueRotate}deg)` }} */ >
                <div className={`card-content-container ${showMenuDetails ? 'show-details' : ''}`}>

                    <div className={`card-title ${showMenuDetails ? 'show-details' : ''}`}>

                        <HambergerButton
                            open={showMenuDetails}
                            setOpen={handleClickMenu}
                        />
                        <div>メニュー別詳細</div>

                    </div>

                    <div className={`card-sub-content ${showMenuDetails ? 'show-details' : ''}`}>
                        <div className='eda-mt-container'>
                            {/* メニューの選択肢を表示 */}
                            <div style={{ height: 20 }}></div>
                            <select className='menu-select' value={selectedMenu} onChange={(e) => setSelectedMenu(e.target.value)}>
                                {menuData && Object.keys(menuData).map((menuName) => (
                                    <option key={menuName} value={menuName}>{menuName}</option>
                                ))}
                            </select>
                            <div style={{ height: 10 }}></div>
                            {/* 選択されたメニューのEDAMenuを表示 */}
                            {selectedMenu && menuData[selectedMenu] && (
                                <EDAMenu key={selectedMenu} menuName={selectedMenu} menuData={menuData[selectedMenu]} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};
export default Panel;


