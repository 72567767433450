// Result.jsx
import React, { useState, useEffect } from 'react';
import { storage } from '../../lib/firebase';
import { ref, getMetadata, getDownloadURL } from 'firebase/storage';
import './result.css';

const Result = ({ chatID }) => {
    const [lastModified, setLastModified] = useState(null);
    const [downloadURL, setDownloadURL] = useState(null);

    useEffect(() => {
        const fetchFileMetadata = async () => {
            try {
                const fileRef = ref(storage, `results/${chatID}/output_report.xlsx`);
                const metadata = await getMetadata(fileRef);

                if (metadata) {
                    const lastModifiedDate = new Date(metadata.updated);
                    setLastModified(lastModifiedDate.toLocaleString());

                    // ダウンロードURLを取得
                    const url = await getDownloadURL(fileRef);
                    setDownloadURL(url);
                } else {
                    setLastModified(null);
                    setDownloadURL(null);
                }
            } catch (error) {
                console.error('Error fetching file metadata:', error);
                setLastModified(null);
                setDownloadURL(null);
            }
        };

        fetchFileMetadata();
    }, [chatID]);

    const handleDownload = () => {
        if (downloadURL) {
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = 'simulation_result.csv';
            link.click();
        }
    };

    return (
        <div className='result-container'>
            <div className='fukidashi'>
                最適化したメディアプランを以下からダウンロードできます
            </div>
            <>
                {lastModified ? (
                    <>
                        <label htmlFor="csvFileInput" className="csv-input-label" onClick={handleDownload}>
                            <svg viewBox="0 0 24 24" className='csv-icon-container' xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <g id="Interface / Download">
                                        <path
                                            className='csv-icon-theme'
                                            id="Vector"
                                            d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12"
                                            stroke="#90feb5"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round">
                                        </path>
                                    </g>
                                </g>
                            </svg>
                        </label>
                        <div className="file-info">
                            <p>{lastModified} 作成</p>
                        </div>
                    </>
                ) : (
                    <div className="file-info">
                        <p>結果ファイルが見つかりません</p>
                    </div>
                )}
            </>
        </div>
    );
};

export default Result;