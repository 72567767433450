// App.jsx
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./lib/firebase";
import Login from "./components/login/Login";
import Home from "./components/home/Home";
import ChatPage from "./components/chatpage/ChatPage";
import Menu from "./components/menu/Menu";
import Upload from "./components/upload/Upload"
import Result from "./components/result/Result";
import './App.css'; // 共通レイアウト用のCSSをインポート
import CpaWave from "./ThreeScene/CpaWave";

const App = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chatID, setChatID] = useState(null)
  /*
  const [cpa, setCpa] = useState([
    15992.254587485811, 21102.52310171875, 28162.521766490165, 27227.676213281597, 42229.205244490964,
    41997.47578393151, 44463.337542430614, 38795.52778392049, 37481.40620066989, 34583.171499950025,
    42910.86072401331, 40608.234652515544, 23597.229923508956, 22429.251802347164, 27504.695734002806,
    34618.976885808406, 32079.24295112833, 33744.80395262549, 36694.17102287315, 37514.899169427285,
    34128.761325511936, 39692.742236154016, 42134.777555601206, 53915.8748918371
  ])*/

  /* 背景の波コントロールする変数 */
  const [cpa, setCpa] = useState([1000000, 1000001, 1000000, 1000001])
  const [cpaNormed, setCpaNormed] = useState([])
  const [waveSpeed, setWaveSpeed] = useState(0.1);
  const [waveStrength, setWaveStrength] = useState(2);
  const [waveState, setWaveState] = useState("weak");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser({
          username: user.displayName,
          email: user.email,
          uid: user.uid,
        });
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);



  /* CPAを正規化 */
  useEffect(() => {
    const min_cpa = Math.min(...cpa);
    const max_cpa = Math.max(...cpa);
    setCpaNormed(cpa.map(value => (value - min_cpa) / (max_cpa - min_cpa) * waveStrength));
  }, [cpa])

  /* 波の動きの激しさをwaveStateで管理 */
  useEffect(() => {
    console.log("save state changed", waveState)
    if (waveState == "weak") {
      setWaveSpeed(0.1);
      setWaveStrength(2);
    } else if (waveState == "normal") {
      setWaveSpeed(0.3);
      setWaveStrength(20);
    } else if (waveState == "strong") {
      setWaveSpeed(1);
      setWaveStrength(40);
    }
  }, [waveState])




  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="app-container">
        {currentUser && <Menu currentUser={currentUser} />}
        <div className="content-container">
          <div className="cpawave-contaienr">
          </div>
          <CpaWave props={{cpa: cpaNormed, waveSpeed: waveSpeed}} />
          <div className="routes-container">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/home" element={currentUser ? <Home currentUser={currentUser} setChatID={setChatID} chatID={chatID} /> : <Navigate to="/login" />} />
              <Route path="/upload" element={currentUser ? (chatID ? <Upload currentUser={currentUser} chatID={chatID} setCpa={setCpa} setWaveState={setWaveState}/> : <Navigate to="/home" />) : <Navigate to="/login" />} />
              <Route path="/plan" element={currentUser ? (chatID ? <ChatPage currentUser={currentUser} chatID={chatID} setWaveState={setWaveState}/> : <Navigate to="/home" />) : <Navigate to="/login" />} />
              <Route path="/result" element={currentUser ? (chatID ? <Result currentUser={currentUser} chatID={chatID} /> : <Navigate to="/home" />) : <Navigate to="/login" />} />
              <Route path="/" element={currentUser ? <Navigate to="/home" /> : <Navigate to="/login" />} />
            </Routes>

          </div>

        </div>
      </div>
    </Router>
  );
};

export default App;
